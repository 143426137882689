body {
  margin: 0;
  font-family: 'Helvetica Neue', 
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e9e9e9;
}
.label-top-top {
  transform: translateY(-34px);
}
.label-top {
  transform: translateY(12px);
}
.label-middle {
  transform: translateY(25px);
}
.label-bottom {
  transform: translateY(35px);
}

.popinfo {
  line-height: 1;
}

.pie-info {
  font-size: calc(2px + 1vmin);
}