.App {
  text-align: center;
  align-content: center;
}

.responsivecontainer {
  height: 100vh;
  width: 100vw;
}

.adjustabletextninety {
  height: 90%;
  width: 90%;
}

.adjustabletext {
  height: 80%;
  width: 80%;
}

.adjustabletextseventy {
  height: 70%;
  width: 70%;
}

.adjustabletextdecriptions {
  height: 40%;
  width: 40%;
}

.adjustabletextmobile {
  height: 95%;
  width: 95%;
}

.wrapper {
  padding: 20px;
}

.graph-row {
  float: left;
  padding: 20px;
  word-wrap: break-word;
}

.toolbarButtons {
  margin-left: 'auto',
}

.selections {
  text-align: left;
  padding: 20px
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: #333333;
}

.App-link {
  color: #61dafb;
}

.MuiAppBar {
  background-color: #ffffff !important;
}

.page-heading {
  padding: 32px;
}

.page-heading-mobile {
  padding: 12px;
  padding-bottom: 0px;
}

.page-heading-title {
  font-weight: 800;
  font-size: calc(12px + 2vmin);
}

.page-heading-subtitle {
  font-size: calc(.5px + 2vmin);
}

.page-heading-notice {
  font-size: calc(1px + 1.5vmin);
}

.chart-title {
  font-weight: 600;
  font-size: calc(8px + 2vmin);
}

.chart-subtitle {
  font-size: calc(.5px + 1.5vmin);
  font-style: italic;
}

.chart-week-befores {
  font-size: calc(2px + 1.5vmin);
}

.row-top {
  padding: 16px;
  padding-top: 0px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.row-middle {
  padding: 16px;
  padding-top: 0px;
  padding-bottom: 0px;
  flex-direction: row;
  justify-content: center;
}

.row-bottom {
  padding: 16px;
  padding-top: 0px;
  flex-direction: row;
  justify-content: center;
}

.row-top-mobile {
  flex-direction: row;
  justify-content: center;
}

.row-middle-mobile {
  flex-direction: row;
  justify-content: center;
}

.row-bottom-mobile {
  flex-direction: row;
  justify-content: center;
}

.card-padding {
  margin: 16px;
}

.pie-padding {
  margin: 16px;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: #f2f2f2;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active, .accordion:hover {
  background-color: #ccc;
}

/* Style the accordion panel. Note: hidden by default */
.panel {
  padding: 0 18px;
  background-color: white;
  display: none;
  overflow: hidden;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
